'use strict';

angular.module('enervexSalesappApp').factory('LengthUtil', function LengthUtil(_, Util) {
	return {
		getLength: function(x,y,z) {
			return Math.sqrt(Math.pow(x,2) + Math.pow(y,2) + Math.pow(z,2))
		},
		getLengthFromV: function(v, design) {
			if (design.viewMode == "2D") {
				return this.getLength(Util.changeDim(v.DimX, design), Util.changeDim(v.DimY, design), 0)
			} else {
				return this.getLength(Util.changeDim(v.DimX, design), Util.changeDim(v.DimY, design), Util.changeDim(v.DimZ, design))
			}
		},
		getLengthFromVAsString: function(v, design, isOverride) {
			if (isOverride) {
				return Util.formatLength(v.Length, false, design, false)
			} else {
				return Util.formatLength(this.getLengthFromV(v, design), false, design, false)
			}
		},
		getHorizontalLength: function(v, design) {
			if (design.viewMode == "2D") {
				return this.getLength(Util.changeDim(v.DimX, design), 0, 0)
			} else {
				return this.getLength(Util.changeDim(v.DimX, design), 0, Util.changeDim(v.DimZ, design))
			}
		},
		get3DegreeLength: function(v, design) {
			var length = this.getHorizontalLength(v, design)
			var tan = Math.tan(3 * Math.PI / 180)
			var result = length * tan
			return Math.abs(result)
		},
		getDiameterString: function(v) {
			var result = null
			if (v.Optimize == "Yes") {
				result = v.result && v.result.Diameter
			} else {
				result = v.Dim1
			}
			return result ? '' + result : "?"
		},
		getDim1: function(v) {
			var result = null
			if (v.Optimize == "Yes") {
				result = v.result && v.result.Diameter
			} else {
				result = v.Dim1
			}
			return parseInt(result)
		},
		getDim2: function(v) {
			var result = null
			if (v.Optimize == "Yes") {
				result = v.result && v.result.Diameter
			} else {
				result = v.Dim2
			}
			return parseInt(result)
		},
	}
})


